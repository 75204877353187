import React from 'react';
import SensorUtils from '../Dashboard/Sensor/SensorUtils';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import {Table} from 'reactstrap';
import {Trans, Translation} from 'react-i18next';
import Shared from '../Shared/Shared';
import {LimitStatisticTableFragment} from './Statistics/LimitStatisticTableFragment';
import {SensorsAllInformationsViewRow} from '../../common/types/DbModel';
import {RunStatistics} from '../Services/Statistics/RunStatistics';

export interface SensorAnalysisStatisticsProps {
	limitAlarmCount: number;
	limitAlarmDetails: any[];
	outUnitsId: number;
	sensor: SensorsAllInformationsViewRow;
	sensorIssueCount: number;
	statistics: RunStatistics;
}

export function SensorAnalysisStatistics(props: Readonly<SensorAnalysisStatisticsProps>) {
	const loggingIntervalInMinutes = props.sensor.logging_interval / 60;
	const details = props.limitAlarmDetails[0];

	return (
		<Table striped responsive className="col-md-12">
			<tbody>
				<tr>
					<td width="15%">
						<Trans i18nKey={'sensor.statistics.module_id'} />
					</td>
					<td width="35%">{props.sensor.serial_number}</td>
					<td width="15%">
						<Trans i18nKey={'sensor.statistics.customer_id'} />
					</td>
					<td width="35%">{props.sensor.customer_id ? props.sensor.customer_id : <Trans i18nKey={'global.n_a'} />}</td>
				</tr>
				<tr>
					<td width="15%">
						<Trans i18nKey={'sensorWizard.summary.loggingInterval'} />
					</td>
					<td width="35%">
						{loggingIntervalInMinutes} <Trans i18nKey={'sensorWizard.summary.minutes'} />
					</td>
					<td width="15%">
						<Trans i18nKey={'sensorWizard.summary.limitAlarms'} />
					</td>
					<td width="35%">{props.limitAlarmCount}</td>
				</tr>

				<LimitStatisticTableFragment
					outUnitsId={props.statistics.outUnitsId}
					zone={{
						limit: details?.upper_limit_4,
						delay: details?.upper_limit_delay_4,
						name: 'H4',
					}}
					zoneStatistic={props.statistics.resultZones[0]}
				/>

				<LimitStatisticTableFragment
					outUnitsId={props.statistics.outUnitsId}
					zone={{
						limit: details?.upper_limit_3,
						delay: details?.upper_limit_delay_3,
						name: 'H3',
					}}
					zoneStatistic={props.statistics.resultZones[1]}
				/>

				<LimitStatisticTableFragment
					outUnitsId={props.statistics.outUnitsId}
					zone={{
						limit: details?.upper_limit_2,
						delay: details?.upper_limit_delay_2,
						name: 'H2',
					}}
					zoneStatistic={props.statistics.resultZones[2]}
				/>

				<LimitStatisticTableFragment
					outUnitsId={props.statistics.outUnitsId}
					zone={{
						limit: details?.upper_limit,
						delay: details?.upper_limit_delay,
						name: 'H1',
					}}
					zoneStatistic={props.statistics.resultZones[3]}
				/>

				<LimitStatisticTableFragment
					outUnitsId={props.statistics.outUnitsId}
					zone={{
						limit: details?.lower_limit,
						delay: details?.lower_limit_delay,
						name: 'L1',
					}}
					zoneStatistic={props.statistics.resultZones[4]}
				/>

				<LimitStatisticTableFragment
					outUnitsId={props.statistics.outUnitsId}
					zone={{
						limit: details?.lower_limit_2,
						delay: details?.lower_limit_delay_2,
						name: 'L2',
					}}
					zoneStatistic={props.statistics.resultZones[5]}
				/>

				<LimitStatisticTableFragment
					outUnitsId={props.statistics.outUnitsId}
					zone={{
						limit: details?.lower_limit_3,
						delay: details?.lower_limit_delay_3,
						name: 'L3',
					}}
					zoneStatistic={props.statistics.resultZones[6]}
				/>

				<tr>
					<td width="15%">
						<Trans i18nKey={'sensor.statistics.highest_value'} />
					</td>
					<td width="35%">
						{SensorUtils.convertTemperature(props.statistics.statisticResult.maxValue, props.statistics.outUnitsId) ? (
							<Translation>
								{(_t, _2) => (
									<>
										{SensorUtils.convertTemperature(
											props.statistics.statisticResult.maxValue,
											props.statistics.outUnitsId
										)}
										&nbsp;
										{Shared.getSensorUnit(props.statistics.outUnitsId)}&nbsp;
										{DateTimeUtils.setDateTimeWithOffset_date_dep(props.statistics.statisticResult.maxValueDateTime)}
									</>
								)}
							</Translation>
						) : (
							<Trans i18nKey={'global.n_a'} />
						)}
					</td>
					<td width="15%">
						<Trans i18nKey={'sensorWizard.steps.sensor_issues'} />
					</td>
					<td width="35%">{props.sensorIssueCount}</td>
				</tr>
				<tr>
					<td width="15%">
						<Trans i18nKey={'sensor.statistics.lowest_value'} />
					</td>
					<td width="35%">
						{SensorUtils.convertTemperature(props.statistics.statisticResult.minValue, props.statistics.outUnitsId) ? (
							<Translation>
								{(_t, _2) => (
									<>
										{SensorUtils.convertTemperature(
											props.statistics.statisticResult.minValue,
											props.statistics.outUnitsId
										)}
										&nbsp;
										{Shared.getSensorUnit(props.statistics.outUnitsId)}&nbsp;
										{DateTimeUtils.setDateTimeWithOffset_date_dep(props.statistics.statisticResult.minValueDateTime)}
									</>
								)}
							</Translation>
						) : (
							<Trans i18nKey={'global.n_a'} />
						)}
					</td>
					<td width="15%">
						<Trans i18nKey={'sensor.statistics.system_issues'} />
					</td>
					<td width="35%">0</td>
				</tr>
				<tr style={{borderBottom: '1px solid #eee'}}>
					<td width="15%">
						<Trans i18nKey={'sensor.statistics.average'} />
					</td>
					<td width="35%">
						{props.statistics.statisticResult.average !== 0 ? (
							<Translation>
								{(_t, _2) => (
									<>
										{SensorUtils.convertTemperature(
											props.statistics.statisticResult.average,
											props.statistics.outUnitsId
										)}
										&nbsp;
										{Shared.getSensorUnit(props.statistics.outUnitsId)}
									</>
								)}
							</Translation>
						) : (
							<Trans i18nKey={'global.n_a'} />
						)}
					</td>
					<td width="15%">
						<Trans i18nKey={'sensor.statistics.mkt'} />
					</td>
					<td width="35%">
						{props.statistics.mkt ? (
							<Translation>
								{(t, _) => (
									<>
										{SensorUtils.convertTemperature(props.statistics.mkt, props.statistics.outUnitsId)}{' '}
										{Shared.getSensorUnit(props.statistics.outUnitsId)}, {t('global.mkt_mol')}
									</>
								)}
							</Translation>
						) : (
							<Trans i18nKey={'global.n_a'} />
						)}
					</td>
				</tr>
			</tbody>
		</Table>
	);
}

export default SensorAnalysisStatistics;
