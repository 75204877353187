import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import {useTranslation} from 'react-i18next';
import AuditTrailEventTypes from '../AuditTrail/AuditTrailEventTypes';
import React, {useState} from 'react';
import {EventDetailList} from './EventDetailList';
import {EventDetail} from '../../common/types';
import {AntStyledTable} from '../Shared/Styles/AntStyledTable';
import {EventRow} from '../../common/types/DbModel';

export interface EventTableProps {
	events: EventRow[];
}

export function EventTable(props: Readonly<EventTableProps>) {
	const [t] = useTranslation();
	const [expandedKeys, setExpandedKeys] = useState([]);

	const columns = [
		{
			title: 'Incident #',
			dataIndex: 'incidents_id',
			width: '10%',
		},
		{
			title: 'Date/Time',
			dataIndex: 'date_occurred',
			width: '15%',
			render: (date: string) => DateTimeUtils.getDateTimeInUserTZ(date),
		},
		{
			title: 'Event',
			dataIndex: 'type',
			render: (type: number) => t('auditTrail.events.' + AuditTrailEventTypes[type].translate),
		},
	];
	const onTableRowExpand = (expanded, record) => setExpandedKeys(expanded ? [record.id] : []);

	return (
		<AntStyledTable<EventRow>
			locale={{emptyText: 'No data'}}
			columns={columns}
			rowKey={(record: EventRow) => record.id}
			dataSource={props.events}
			expandedRowKeys={expandedKeys}
			onExpand={onTableRowExpand}
			expandable={{
				expandRowByClick: true,
				expandedRowRender: (record, index, indent, expanded) => (
					<EventDetailList eventDetails={record.details.map(d => new EventDetail(d))} />
				),
			}}
		/>
	);
}
