import React, {MouseEvent, useContext, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import SerialNumberInfo from '../../Shared/SerialNumberInfo';
import {SensorActions} from '../../../common/helpers/SensorActions';
import {useHistory} from 'react-router-dom';
import {Button, Dropdown, MenuProps, Spin} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import {ShowDeleteSensorDialog} from '../../Shared/Dialogs/ShowDeleteSensorDialog';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import StartCalibrationModal from '../../Calibration/StartCalibrationModal';
import {AuthUtils, DeviceUtils} from '../../../common/util';
import Message from '../../Shared/Components/Message';
import {getAdditionallyAffectedSensorsAsync} from '../../../common/util/SensorChannelGrouping';
import {UI_EVENT} from '../../Shared/Constants/Events';
import pubsub from 'pubsub-js';
import {MultiContext} from '../../Infrastructure/Authorization/Context/MultiContext';
import {MenuItemType} from 'antd/es/menu/interface';
import CalibrationService from '../../../components/Services/Calibrations/CalibrationService';
import {SensorInfo} from '../../Services/Sensors/SensorInfo';
import {CurrentCalibrationState} from '../../Calibration/Model/CalibrationState';
import {Sensor} from '../../Common/Types/Sensor';

interface SensorDropdownProps {
	sensor: SensorInfo;
	buttonSize?: SizeType;
}

export const SensorDropdown = (props: SensorDropdownProps) => {
	const context = useContext(MultiContext);
	const history = useHistory();
	const [t, i18n] = useTranslation();

	const [loading, setLoading] = useState(false);
	const [calibrationState, setCalibrationState] = useState<CurrentCalibrationState>();
	const [startCalibrationModalVisible, setStartCalibrationModalVisible] = useState(false);
	const [calibrationAffectedSensors, setCalibrationAffectedSensors] = useState<Sensor[]>([]);
	const [menuProps, setMenuProps] = useState<MenuProps>({items: []});

	function getMenuItems(sensorInfo: SensorInfo, calibrationState?: CurrentCalibrationState, loading?: boolean): any[] {
		const sensor = new Sensor(sensorInfo);
		return [
			{
				id: 'btnAnalyse',
				title: t('sensor.analyseSensor'),
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.viewAnalysis,
				roles: userRoles.default,
				showOnRetired: true,
				onClick: () => SensorActions.Analyse(sensor, history),
			},
			{
				id: 'btnSensorShowSummary',
				title: t('sensor.sensorShowSummary'),
				onClick: () => SensorActions.ShowSummary(sensor, history),
			},
			{type: 'divider'},
			{
				id: 'btnActivateAlarming',
				title: t('sensor.activateAlarming'),
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.deactivateAlarming,
				roles: userRoles.default,
				onClick: () => SensorActions.SetAlarmingEnabled(sensor, true),
				disabled: !!calibrationState?.State,
				hide: SerialNumberInfo.isValidLiberoG(sensorInfo.serial_number) || !sensorInfo.muting_enabled || !sensorInfo.is_muted,
				isAsyncItem: true,
			},
			{
				id: 'btnDeactivateAlarming',
				title: t('sensor.deactivateAlarming'),
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.deactivateAlarming,
				roles: userRoles.default,
				onClick: () => SensorActions.SetAlarmingEnabled(sensor, false),
				hide: SerialNumberInfo.isValidLiberoG(sensorInfo.serial_number) || !sensorInfo.muting_enabled || sensorInfo.is_muted,
			},
			{
				id: 'btnConfiguration',
				title: t('sensor.configuration'),
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.editSensor,
				roles: userRoles.default,
				onClick: () => SensorActions.ConfigureSensor(sensor, history),
				disabled: !!calibrationState?.State,
				hide: DeviceUtils.IsLiberoGx(sensorInfo.serial_number),
				isAsyncItem: true,
			},
			{
				id: 'btnStartNewRun',
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.startNewRun,
				roles: userRoles.default,
				onClick: () => SensorActions.ConfigureSensor(sensor, history),
				disabled: !(sensorInfo.is_multi_run && sensorInfo.start_multirun_enabled && !calibrationState?.State),
				hide: !sensorInfo.is_multi_run,
				title: t('sensor.newRun'),
				isAsyncItem: true,
			},
			{type: 'divider'},
			{
				id: 'btnStartCalibration',
				roles: [...userRoles.calibrator],
				disabled: !calibrationState?.Options?.StartCalibrationPossible,
				hide: calibrationState?.Options?.StopCalibrationPossible || DeviceUtils.IsLiberoGx(sensorInfo.serial_number),
				onClick: () => setStartCalibrationModalVisible(true),
				title: t('sensor.startCalibration'),
				isAsyncItem: true,
			},
			{
				id: 'btnStopCalibration',
				roles: [...userRoles.calibrator],
				disabled: !calibrationState?.Options?.StopCalibrationPossible,
				hide: !calibrationState?.Options?.StopCalibrationPossible || DeviceUtils.IsLiberoGx(sensorInfo.serial_number),
				onClick: () => SensorActions.StopCalibration(sensor),
				title: t('sensor.stopCalibration'),
				isAsyncItem: true,
			},
			{
				id: 'btnReplaceSensor',
				title: t('sensor.replaceSensor'),
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.replaceSensor,
				roles: userRoles.default,
				hide: DeviceUtils.IsLiberoGx(sensorInfo.serial_number),
				disabled: sensorInfo.mr_locked_configuration || !!calibrationState?.State,
				onClick: () => SensorActions.ReplaceSensor(sensor, history),
				isAsyncItem: true,
			},
			{
				id: 'btnManageLicense',
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.manageLicence,
				roles: userRoles.default,
				showOnRetired: true,
				onClick: () => SensorActions.ManageLicense(history),
				title: t('sensor.manageLicense'),
			},
			{type: 'divider'},
			{
				id: 'btnDelete',
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.deleteSensor,
				roles: userRoles.default,
				showOnRetired: true,
				onClick: () => deleteSensor(),
				title: t('sensor.deleteSensor'),
			},
			{
				id: 'btnCertificate',
				access: accessPermissions.devicesview.child.dashboard.child.sensor.child.downloadFactoryCalibrationCertificate,
				roles: userRoles.default,
				onClick: () => SensorActions.DownloadCertificate(sensor, i18n),
				title: t('sensor.calibrationCertificate'),
			},
		];
	}

	useEffect(() => {
		const menuItems = getMenuItems(props.sensor, calibrationState, loading)
			.filter(hideWithMissingAccessRights)
			.filter(m => !m.hide)
			.filter(hideMenuItemsForRetiredSensor)
			.filter(removeDuplicateDividers)
			.map(m => {
				if (m.type) {
					return {type: m.type};
				}

				if (m.isAsyncItem) {
					m.disabled = loading || m.disabled;
					m.icon = loading ? <Spin size="small" /> : m.icon;
				}

				return {
					disabled: m.disabled,
					label: m.title,
					icon: m.icon,
					key: `${props.sensor.id}-${m.title}`,
					onClick: m.onClick,
					id: m.id,
				} as MenuItemType;
			});

		setMenuProps({items: menuItems});
	}, [calibrationState, loading, props.sensor]);

	useEffect(() => {
		if (startCalibrationModalVisible) {
			getAdditionallyAffectedSensorsAsync(new Sensor(props.sensor)).then(sensors => setCalibrationAffectedSensors(sensors));
		}
	}, [startCalibrationModalVisible]);

	function onCalibrationStarted() {
		setStartCalibrationModalVisible(false);
		if (DeviceUtils.IsLiberoGx(props.sensor.serial_number) || DeviceUtils.IsEcologProXG(props.sensor.serial_number)) {
			Message.warning(<Trans i18nKey={'calibrations.startSent'} />, <Trans i18nKey={'calibrations.start'} />, {autoClose: 20000});
		}

		pubsub.publish(`${UI_EVENT.CALIBRATION_STATUS_CHANGED}.${props.sensor.id}`, {
			sensorId: props.sensor.id,
		});

		calibrationAffectedSensors.forEach(affectedSensor =>
			pubsub.publish(`${UI_EVENT.CALIBRATION_STATUS_CHANGED}.${affectedSensor.Id}`, {
				sensorId: affectedSensor.Id,
			})
		);
	}

	function deleteSensor() {
		ShowDeleteSensorDialog(props.sensor).catch(() => {
			console.error('Error deleting sensor');
		});
	}

	function hideMenuItemsForRetiredSensor(menuItem: any): boolean {
		if (menuItem.type === 'divider') {
			return true;
		}
		return !props.sensor.is_retired || menuItem.showOnRetired;
	}

	function removeDuplicateDividers(menuItem: any, idx: number, array: any[]): boolean {
		if (menuItem.type !== 'divider') {
			return true;
		}

		if (idx === 0) {
			return false;
		}

		return array[idx - 1].type !== 'divider';
	}

	function hideWithMissingAccessRights(menuItem: any): boolean {
		if (menuItem.type === 'divider') {
			return true;
		}
		let hasRoleAccess: boolean = true;
		if (menuItem.roles) {
			hasRoleAccess = menuItem.roles.some(role => context.AccessContext.user.access_settings.settings.roles.includes(role));
		}

		let hasAccess: boolean = true;
		if (menuItem.access) {
			const accessElement = AuthUtils.FindAccessElement(menuItem.access.path, context.AccessContext.user.access_settings.access);
			hasAccess = accessElement?.allow ?? hasAccess;
		}
		return hasRoleAccess && hasAccess;
	}

	function onOpenChange(open: boolean) {
		if (open) {
			setLoading(true);

			const calibrationService = new CalibrationService();
			calibrationService
				.getCurrentCalibrationStatus(props.sensor.id)
				.then(calibrationStates => {
					const calibrationState = calibrationStates[0];
					setCalibrationState(calibrationState);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}

	return (
		<>
			<StartCalibrationModal
				isVisible={startCalibrationModalVisible}
				sensor={new Sensor(props.sensor)}
				addidionallyAffectedSensor={calibrationAffectedSensors}
				isLoading={false}
				onChangeModalVisibility={() => setStartCalibrationModalVisible(!startCalibrationModalVisible)}
				startCallback={onCalibrationStarted}
			/>
			<Dropdown menu={menuProps} trigger={['click']} arrow={{pointAtCenter: true}} onOpenChange={onOpenChange}>
				<Button
					icon={<MenuOutlined />}
					onClick={(e: MouseEvent) => e.preventDefault()}
					size={props.buttonSize}
					id={`SensorDropdown${props.sensor.device_name}`}
				/>
			</Dropdown>
		</>
	);
};

export default SensorDropdown;
