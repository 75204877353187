import dayjs from "dayjs";
import DateTimeUtils from "./DateTimeUtils";

/**
 * Left closed / right open interval by default, can be closed if {isRightOpen} is false
 */
export class TimeRange {
	public start: dayjs.Dayjs;
	public end: dayjs.Dayjs;
	public isRightOpen: boolean = true;

	constructor(start: dayjs.Dayjs, end: dayjs.Dayjs) {
		this.start = start;
		this.end = end;
	}

	/**
	 * Adjusts the start and end times of a TimeRange instance to the current user's timezone offset.
	 *
	 * @return {TimeRange} A new TimeRange object adjusted to the user's timezone offset.
	 */
	public inUserTz(): TimeRange {
		const offset = DateTimeUtils.getCurrentUserTZOffset();
		return new TimeRange(this.start.utcOffset(offset), this.end.utcOffset(offset));
	}

	/**
	 * Converts the object into an array containing the start and end Dayjs instances.
	 *
	 * @return {[dayjs.Dayjs, dayjs.Dayjs]} An array where the first element is the start date and the second element is the end date.
	 */
	public toArray(): [dayjs.Dayjs, dayjs.Dayjs] {
		return [this.start, this.end];
	}

	/**
	 * Converts the current time range into a right-closed range by adjusting the end time.
	 * Sets the `isRightOpen` property of the resulting range to false.
	 *
	 * @return {TimeRange} A new TimeRange instance representing a right-closed range.
	 */
	public asClosedRight(): TimeRange {
		const rightClosed = new TimeRange(this.start, this.end.subtract(1, 'millisecond'));
		rightClosed.isRightOpen = false;
		return rightClosed;
	}
}
