import React from 'react';

export function AlarmStateComponent(props: Readonly<{isAlarmed: boolean}>) {
	let imgProps: {id: string; alt: string; src: string};
	if (props.isAlarmed) {
		imgProps = {
			alt: 'Alarm status',
			id: 'status-alarm',
			src: '/img/alarmState/Alarm.png',
		};
	} else {
		imgProps = {
			alt: 'Alarm status ok',
			id: 'status-ok',
			src: '/img/alarmState/AlarmOK.png',
		};
	}
	return <img alt="Alarm status" {...imgProps} />;
}

export default AlarmStateComponent;
