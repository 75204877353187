import {ContentWrapper, ViewWrapper} from './Layout';
import {ViewHeader} from './Common';
import {Spin} from 'antd';
import React from 'react';

export function LoadingComponent() {
	return (
		<ViewWrapper>
			<ViewHeader heading={''} />
			<ContentWrapper>
				<Spin size={'large'} />
			</ContentWrapper>
		</ViewWrapper>
	);
}
