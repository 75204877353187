import React, { Component } from "react";
import { Button, Input } from "reactstrap";
import { Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion";
import Datetime from "react-datetime";

/** @deprecated use TimeRangePicker instead */
class AccordionFilter extends Component {
	render() {
		return (
			<Accordion style={this.props.style}>
				<AccordionItem id="accordionFilter" expanded={this.props.expanded}>
					<AccordionItemTitle id="accordionSensorAnalysisPeriodTitle">
						<h5 className="u-position-relative">
							{this.props.title}
							<div className="accordion__arrow" role="presentation" />
						</h5>
					</AccordionItemTitle>
					<AccordionItemBody>
						{typeof this.props.showRun === 'function' ? (
							<span>
								<Button onClick={this.props.showRun} color="link" id="showRun">
									Show Run
								</Button>{' '}
								|
							</span>
						) : null}

						{typeof this.props.showLast7Days === 'function' ? (
							<span>
								<Button onClick={this.props.showLast7Days} color="link" id="last7days">
									Last 7 Days
								</Button>{' '}
								|
							</span>
						) : null}

						{typeof this.props.showLast30Days === 'function' ? (
							<span>
								<Button onClick={this.props.showLast30Days} color="link" id="last30days">
									Last 30 Days
								</Button>{' '}
								|
							</span>
						) : null}
						{typeof this.props.showLastWeek === 'function' ? (
							<span>
								<Button onClick={this.props.showLastWeek} color="link" id="previousWeek">
									Previous Week
								</Button>{' '}
								|
							</span>
						) : null}

						{typeof this.props.showLastMonth === 'function' ? (
							<span>
								<Button onClick={this.props.showLastMonth} color="link" id="previousCalendarMonth">
									Previous Calendar Month
								</Button>{' '}
								|
							</span>
						) : null}

						{typeof this.props.showLastYear === 'function' ? (
							<span>
								<Button onClick={this.props.showLastYear} color="link" id="previousYear">
									Previous Year
								</Button>
								{typeof this.props.showAll === 'function' ? <span>|</span> : null}
							</span>
						) : null}

						{typeof this.props.showAll === 'function' ? (
							<span>
								<Button onClick={this.props.showAll} color="link" id="showAll">
									All
								</Button>
							</span>
						) : null}

						<br />
						<br />
						<div className="form-group">
							<span>From &nbsp;</span>
							<div className="react-datepicker-wrapper">
								<div className="react-datepicker__input-container">
									<Datetime
										inputProps={{className: 'form-control', id: 'dateFrom'}}
										timeFormat="HH:mm"
										dateFormat="DD.MM.YYYY"
										value={this.props.dateFrom}
										onChange={this.props.setDateFrom}
										closeOnSelect={true}
									/>
								</div>
							</div>
							<span>&nbsp;To&nbsp;</span>

							<div className="react-datepicker-wrapper">
								<div className="react-datepicker__input-container">
									<Datetime
										inputProps={{className: 'form-control', id: 'dateTo'}}
										timeFormat="HH:mm"
										dateFormat="DD.MM.YYYY"
										value={this.props.dateTo}
										onChange={this.props.setDateTo}
										closeOnSelect={true}
									/>
								</div>
							</div>
							{'\u00A0'}
							{'\u00A0'}
							<Button
								id="showDateRange"
								className="btn btn-primary"
								color="primary"
								style={{marginBottom: '3px'}}
								onClick={this.props.showSelectedDateTime}
							>
								Show
							</Button>

							{typeof this.props.showPreviousTimePeriod === 'function' ? (
								<span>
									{'\u00A0'}
									<Button
										className="btn btn-primary"
										color="primary"
										style={{marginBottom: '3px'}}
										onClick={this.props.showPreviousTimePeriod}
									>
										Previous
									</Button>
								</span>
							) : null}

							{typeof this.props.showNextTimePeriod === 'function' ? (
								<span>
									{'\u00A0'}
									<Button
										className="btn btn-primary"
										color="primary"
										style={{marginBottom: '3px'}}
										onClick={this.props.showNextTimePeriod}
									>
										Next
									</Button>
								</span>
							) : null}
						</div>

						{typeof this.props.activeOrAcknowledgedOnlyChecked === 'function' ? (
							<div>
								<div className="checkbox c-checkbox float-left mt-0">
									<label>
										<Input
											type="checkbox"
											onChange={this.props.activeOrAcknowledgedOnlyChecked}
											value={this.props.active_unacknowledged}
										/>
										<span className="fa fa-check" />
										Active or unacknowledged only
									</label>
									<br />
								</div>
								<br />
							</div>
						) : null}
					</AccordionItemBody>
				</AccordionItem>
			</Accordion>
		);
	}
}

export default AccordionFilter;
