import {DevUtils} from '../../common/util';
import request from '../../components/Infrastructure/Requests/Request';
import Authentication from '../../components/Infrastructure/Authentication/Authentication';
import appSettings from '../../components/Infrastructure/Settings/AppSettings';
import fileSaver from 'file-saver';

const LocalhostPrefix: string = DevUtils.UseLocal('TEMPLATE_SERVICE') ? 'http://localhost:8080/' : appSettings.getBaseUrl();
const Route: string = `${LocalhostPrefix}report`;

export class ReportService {
	static async DownloadDeviceConfigurationReport(config: object): Promise<void> {
		try {
			const response = await request({
				url: `${Route}/deviceConfiguration`,
				method: 'POST',
				token: Authentication.getToken(),
				data: config,
				responseType: 'blob',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/pdf',
				},
			});

			this.saveReportAsFile(response);
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}

	static async DownloadSensorConfigurationReport(serialNumber: string, sensorIds: number[]): Promise<void> {
		try {
			const queryParams = sensorIds.map(id => `sensorIds=${id}`).join('&');
			const response = await request({
				url: `${Route}/deviceConfiguration/${serialNumber}?${queryParams}`,
				method: 'GET',
				token: Authentication.getToken(),
				responseType: 'blob',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/pdf',
				},
			});

			this.saveReportAsFile(response);
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}

	private static saveReportAsFile(response: any) {
		const contentDisposition = response.headers['content-disposition'];
		const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
		const filename = match[1];

		fileSaver.saveAs(response.data, filename);
	}
}
