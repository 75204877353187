import request from '../../../components/Infrastructure/Requests/Request';
import pubsub from 'pubsub-js';
import {UI_EVENT} from '../../../components/Shared/Constants/Events';
import {SensorsAllInformationsViewRow} from '../../types/DbModel';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
import {DiscoverModuleResponse} from './DiscoverModuleResponse';
import {SensorInfo} from 'components/Services/Sensors/SensorInfo';
import {IServerSideGetRowsRequest} from 'ag-grid-enterprise';
import {Sensor} from '../../../components/Common/Types/Sensor';
import {getFilterParams, getSortParms} from '../Helpers/AgGridReactSSRQueryBuilder';
import {RunInfo} from '../../types';

export class SensorService {
	public static async DiscoverModule(serialNumber: string): Promise<DiscoverModuleResponse> {
		try {
			const response = await request({
				url: '/rpc/discover_module',
				method: 'POST',
				data: {serial_number: serialNumber},
				token: Authentication.getToken(),
			});
			return response.data;
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}

	public static async DiscoverRun(sensorId: number): Promise<{
		AffectedSensors: {id: number; name: string}[];
		Configuration: object;
	}> {
		try {
			const response = await request({
				url: '/rpc/discover_run',
				method: 'POST',
				data: {s_id: sensorId},
				token: Authentication.getToken(),
			});
			return {AffectedSensors: response.data.affected_sensors, Configuration: response.data.configuration};
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}

	public static async SetAlarmingEnabled(sensor: Sensor, alarmingEnabled: boolean): Promise<void> {
		try {
			await request({
				url: '/rpc/activate_alarming',
				method: 'POST',
				token: Authentication.getToken(),
				data: {
					sensors_id: sensor.Id,
					activate: alarmingEnabled,
				},
			});
			pubsub.publish(`${UI_EVENT.SENSOR_ALARMING_ENABLED_CHANGED}.${sensor.Id}`, {
				AlarmingEnabled: alarmingEnabled,
				SensorId: sensor.Id,
			});
		} catch (e) {
			console.error(e);
			return Promise.reject(e);
		}
	}

	public static async GetModuleSensors(serial_number: string): Promise<SensorInfo[]> {
		try {
			const response = await request({
				url: '/sensors_info?serial_number=eq.' + serial_number,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return response.data;
		} catch (e) {
			return await Promise.reject(new Error('Failed to get module sensors'));
		}
	}

	public static async GetSensorInfo(sensorId: number): Promise<SensorInfo> {
		try {
			const response = await request({
				url: '/sensors_info?id=eq.' + sensorId,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return response.data[0];
		} catch (e) {
			return await Promise.reject(new Error('Failed to get SensorInfo'));
		}
	}

	public static async GetSensorsFromGridInfo(params: IServerSideGetRowsRequest): Promise<{
		total: number;
		sensors: SensorInfo[];
	}> {
		let sortParms = getSortParms(params);
		let filterParams = getFilterParams(params);

		const res = await request({
			url: `/sensors_info?limit=${params.endRow - params.startRow}&offset=${params.startRow}${sortParms}${filterParams}`,
			method: 'GET',
			token: Authentication.getToken(),
			headers: {Prefer: 'count=exact'},
		});

		return {
			total: res.headers['content-range'].split('/')[1],
			sensors: res.data,
		};
	}

	public static async GetChannelIds(): Promise<number[]> {
		try {
			const response = await request({
				url: '/sensors_overview?select=channel_id',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return [...new Set<number>(response.data.map((d: {channel_id: number}) => d.channel_id))];
		} catch (e) {
			return await Promise.reject(new Error('Failed to get channel ids'));
		}
	}

	public static async GetDeviceStates(): Promise<string[]> {
		try {
			const response = await request({
				url: '/sensors_overview?select=device_state',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return [...new Set<string>(response.data.map((d: {device_state: string}) => d.device_state))];
		} catch (e) {
			return await Promise.reject(new Error('Failed to get device states'));
		}
	}

	public static async GetModuleShortNames(): Promise<string[]> {
		try {
			const response = await request({
				url: '/sensors_overview?select=module_short_name',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return [...new Set<string>(response.data.map((d: {module_short_name: string}) => d.module_short_name))];
		} catch (e) {
			return await Promise.reject(new Error('Failed to get module short names'));
		}
	}

	public static async GetSensorFamilies(): Promise<string[]> {
		try {
			const response = await request({
				url: '/sensors_overview?select=module_family',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return [...new Set<string>(response.data.map((d: {module_family: string}) => d.module_family))];
		} catch (e) {
			console.error(e);
			return await Promise.reject(new Error('Failed to get sensor families'));
		}
	}

	public static async AllInformation(sensorId: number): Promise<SensorsAllInformationsViewRow> {
		try {
			const response = await request({
				url: '/sensors_all_informations?id=eq.' + sensorId,
				method: 'GET',
				headers: {Accept: 'application/vnd.pgrst.object'},
				token: Authentication.getToken(),
			});
			return response.data;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public static async GetMultiUseRunInfo(sensorId: number): Promise<RunInfo[]> {
		try {
			const response = await request({
				url: '/rpc/get_run_info',
				method: 'POST',
				data: {s_id: sensorId},
				token: Authentication.getToken(),
			});
			return response.data.map(r => new RunInfo(r));
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
