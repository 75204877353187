import {ColDef} from 'ag-grid-enterprise';
import {DeviceUtils} from '../../common/util';
import {AlarmStateFormatter, DateTimeFormatter, DeviceStateFormatter} from './ValueFormatters';
import {
	MeasurementDateGetter,
	MeasurementValueGetter,
	RunNumberValueGetter,
	RunStartDateValueGetter,
	TiltAngleGetter,
} from './ValueGetters';
import {CONSTANTS} from '../Shared/Constants/Constants';
import {nameof} from '../../common/util/Nameof';
import {LimitAlarmStateCellClassRules} from './CellClassRules';
import styles from './styles.module.scss';
import {memo} from 'react';
import {HamburgerMenu} from './HamburgerMenu';
import {SensorInfo} from '../Services/Sensors/SensorInfo';
import {SensorService, SensorGroupService} from '../../common/services';

const metaDataCols: ColDef<SensorInfo>[] = Array.from({length: 7}, (_, i) => i + 1).map(idx => ({
	colId: `metadata_${idx + 1}`,
	valueGetter: m => {
		if (!m?.data) {
			return '';
		}
		if (DeviceUtils.IsLiberoGx(m.data.serial_number)) {
			return m.data[`metadata_${idx + 1}`] ?? '';
		}
		return CONSTANTS.NOT_AVAILABLE;
	},
	headerName: `Metadata ${idx + 1}`,
	filter: 'agTextColumnFilter',
}));

const f = nameof<SensorInfo>;
const ColumnDefinitions: ColDef<SensorInfo>[] = [
	{
		cellClass: styles.MenuCell,
		cellRenderer: memo(HamburgerMenu),
		colId: 'Menu',
		filter: false,
		resizable: false,
		pinned: true,
		sortable: false,
		enableRowGroup: false,
		minWidth: 60,
		width: 60,
		suppressMenu: true,
		lockVisible: true,
		suppressMovable: true,
		lockPosition: 'left',
	},
	{
		colId: 'device_name',
		valueGetter: m => m.data.metadata_1 ?? m.data?.device_name,
		headerTooltip: 'Metadata 1 or Sensor Name',
		headerName: 'Device Name',
		filter: 'agTextColumnFilter',
	},
	{
		colId: 'serial_number',
		headerName: 'Serial Number',
		valueGetter: m => m?.data?.serial_number,
		filter: 'agTextColumnFilter',
	},
	{
		colId: 'channel_id',
		headerName: 'Channel ID',
		valueGetter: m => m?.data?.channel_id,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: params => {
				SensorService.GetChannelIds().then(channelIds => params.success(channelIds));
			},
		},
	},
	{colId: 'custom_id', headerName: 'Customer ID', valueGetter: m => m?.data?.custom_id, filter: 'agTextColumnFilter'},
	{
		colId: 'alarm_state',
		headerName: 'Alarm state',
		valueGetter: m => m.data?.alarm_state,
		valueFormatter: AlarmStateFormatter,
		cellClassRules: LimitAlarmStateCellClassRules,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ['None', 'OK', 'ALARM', 'DEACTIVATED'],
		},
	},
	{
		colId: 'last_measurement_tstamp',
		headerName: 'Measurement Date',
		valueGetter: MeasurementDateGetter,
		valueFormatter: DateTimeFormatter,
		filter: 'agDateColumnFilter',
	},
	{
		colId: 'last_measurement_value',
		headerName: 'Measurement Value',
		valueGetter: MeasurementValueGetter,
		filter: 'agNumberColumnFilter',
	},
	{
		colId: 'device_state',
		headerName: 'Device State',
		valueGetter: m => m.data?.device_state,
		valueFormatter: DeviceStateFormatter,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: params => {
				SensorService.GetDeviceStates().then(deviceStates => params.success(deviceStates));
			},
		},
	},
	{
		colId: 'ts_start',
		headerName: 'Start Date',
		valueGetter: RunStartDateValueGetter,
		valueFormatter: DateTimeFormatter,
		filter: 'agDateColumnFilter',
	},
	{colId: 'run_count', headerName: 'Run Number', valueGetter: RunNumberValueGetter, filter: 'agNumberColumnFilter'},
	{
		colId: 'module_family',
		headerName: 'Device Family',
		filter: 'agSetColumnFilter',
		valueGetter: m => m.data?.module_family,
		filterParams: {
			values: params => {
				SensorService.GetSensorFamilies().then(deviceFamilies => params.success(deviceFamilies));
			},
		},
	},
	{
		colId: 'module_short_name',
		headerName: 'Device Type',
		valueGetter: m => m.data?.module_short_name,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: params => {
				SensorService.GetModuleShortNames().then(moduleShortNames => params.success(moduleShortNames));
			},
		},
	},
	...metaDataCols,
	{
		colId: 'sensor_groups_array',
		headerName: 'Sensor Groups',
		valueGetter: m => {
			const groups = m.data?.sensor_groups_array;
			if (groups?.length === 0) groups.push('');
			return groups;
		},
		filter: 'agSetColumnFilter',
		filterParams: {
			values: params => {
				SensorGroupService.GetSensorGroups().then(sensorGroups => params.success(sensorGroups.map(g => g.Name)));
			},
			defaultToNothingSelected: true,
		},
		sortable: false,
	},
];

export function getColumnDefinitions(props: {tiltEnabled: boolean}) {
	if (props.tiltEnabled && !ColumnDefinitions.find(c => c.colId === 'last_measurement_tilt_angle')) {
		const valueIdx = ColumnDefinitions.findIndex(c => c.colId === 'last_measurement_value') + 1;
		ColumnDefinitions.splice(valueIdx, 0, {
			colId: 'last_measurement_tilt_angle',
			headerName: 'Tilt Angle',
			valueGetter: TiltAngleGetter,
			filter: 'agNumberColumnFilter',
		});
	}

	return ColumnDefinitions;
}
