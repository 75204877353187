import {DateTimeUtils} from '../../Infrastructure/DateTime/DateTimeUtils';
import type {TimeRangePickerProps as AntTimeRangePickerProps} from 'antd';
import {Col, DatePicker, Row} from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import styles from '../styles.module.css';
import {useSearchParams} from 'react-router-dom-v5-compat';
import {TimeRange} from '../../Infrastructure/DateTime/TimeRange';

const {RangePicker} = DatePicker;

export interface AdditionalTimeRange {
	name: string;
	value: TimeRange;
}

export interface TimeRangePickerProps {
	onTimeRangeChanged: (value: TimeRange) => void;
	additionalTimeRanges?: AdditionalTimeRange[];
	value?: TimeRange;
}

export function TimeRangePicker(props: TimeRangePickerProps): React.JSX.Element {
	const searchParamStart = 'timeRangeStart';
	const searchParamEnd = 'timeRangeEnd';
	const [searchParams, setSearchParams] = useSearchParams();

	let value = props.value ?? DateTimeUtils.getXDaysBeforeDay(7);
	if (searchParams.has(searchParamStart)) {
		value.start = dayjs.unix(parseInt(searchParams.get(searchParamStart)));
	}
	if (searchParams.has(searchParamEnd)) {
		value.end = dayjs.unix(parseInt(searchParams.get(searchParamEnd)));
	}

	function onRangePickerChange(dates: [dayjs.Dayjs, dayjs.Dayjs], _: [string, string]) {
		if (!dates || dates.length !== 2) {
			return;
		}
		const timeRange: TimeRange = new TimeRange(dates[0], DateTimeUtils.getOpenInterval(dates[1], 'day'));
		onTimeRangeSelected(timeRange);
	}

	function onTimeRangeSelected(timeRange: TimeRange) {
		setSearchParams({
			...searchParams,
			[searchParamStart]: timeRange.start.unix().toString(),
			[searchParamEnd]: timeRange.end.unix().toString(),
		});
		props.onTimeRangeChanged(timeRange);
	}

	const additionalPresets: AntTimeRangePickerProps['presets'] =
		props.additionalTimeRanges
			?.filter(additionTimeRange => !!additionTimeRange)
			.map(additionalTimeRange => ({
				label: additionalTimeRange.name,
				value: additionalTimeRange.value.inUserTz().asClosedRight().toArray(),
			})) ?? [];

	const rangePresets: AntTimeRangePickerProps['presets'] = [
		...additionalPresets,
		{label: 'Today', value: DateTimeUtils.getTimePeriod(dayjs(), 'd', 0).asClosedRight().toArray()},
		{label: 'Last 7 Days', value: DateTimeUtils.getXDaysBeforeDay(7).asClosedRight().toArray()},
		{
			label: 'Previous week',
			value: DateTimeUtils.getTimePeriod(dayjs(), 'isoWeek', -1).asClosedRight().toArray(),
		},
		{label: 'Previous month', value: DateTimeUtils.getTimePeriod(dayjs(), 'month', -1).asClosedRight().toArray()},
		{label: 'All', value: DateTimeUtils.getAllTimeRange().asClosedRight().toArray()},
	];

	return (
		<Row className={styles.ComponentRow}>
			<Col>
				<Row justify={'center'}>
					<RangePicker
						presets={rangePresets}
						value={value.asClosedRight().toArray()}
						format={'DD.MM.YYYY HH:mm'}
						onChange={onRangePickerChange}
					/>
				</Row>
			</Col>
		</Row>
	);
}
