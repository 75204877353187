import React from 'react';
import {Trans} from 'react-i18next';
import {Button} from 'antd';

interface ReportButtonProps {
	loadingReport: boolean;
	onClick: (e?: unknown) => void;
}

export const ReportButton = (props: Readonly<ReportButtonProps>) => {
	return (
		<Button type="primary" onClick={props.onClick} size="large" loading={props.loadingReport}>
			<span className="fa fa-xl fa-solid elpro-Report mr-2" />
			<Trans i18nKey={'buttons.report'} />
		</Button>
	);
};

export default ReportButton;
