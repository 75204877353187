import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import {Message, ReportButton} from '../../Shared/Components';
import {Trans} from 'react-i18next';
import React, {createRef, useState} from 'react';
import {ExportType} from '../../Common/Export/ExportType';
import {ExportButton} from '../../Common/Export/ExportButton';
import {LiveLinkService} from '../../LiveLink/LiveLinkService';
import {Button, Input, InputRef, Modal} from 'antd';

interface ReportComponentProps {
	sensorId: number;
	debugReport: any;
	reportLocalhost: any;
	loadingReport: any;
	report: any;
	exportFunction: any;
	exportShowGeo: any;
}

export const ReportComponent = (props: ReportComponentProps) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [liveLink, setLiveLink] = useState('');
	const liveLinkInputRef = createRef<InputRef>();

	const getHostName = () => {
		let hostName = window.location.hostname;
		if (window.location.port) hostName += ':' + window.location.port;
		return hostName;
	};

	const getLiveLink = _ => {
		LiveLinkService.getLiveLink({sensor_id: props.sensorId})
			.then(response => getHostName() + '/liveLink/' + response.data)
			.then(setLiveLink)
			.then(_ => setModalOpen(true))
			.catch(e => new Error(e));
	};

	return (
		<div style={{display: 'flex', justifyContent: 'right'}}>
			<Modal
				okText="Copy to clipboard"
				title="You can share this link"
				open={modalOpen}
				onOk={() =>
					navigator.clipboard.writeText(liveLink).then(() => {
						Message.success('Success', 'Link copied to clipboard!');
						setModalOpen(false);
					})
				}
				onCancel={_ => setModalOpen(false)}
				afterOpenChange={open => open && liveLinkInputRef.current.focus({cursor: 'all'})}
			>
				<Input type="text" value={liveLink} ref={liveLinkInputRef} />
			</Modal>
			{props.debugReport && (
				<div style={{padding: '0 10px'}}>
					<Button id="btnReport" type="primary" danger={true} size="large" onClick={props.reportLocalhost}>
						<span>
							<Trans i18nKey={'buttons.report'} />
						</span>
					</Button>
				</div>
			)}

			<Access
				access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.selectPreviousSensorRuns}
				roles={userRoles.default}
			>
				<div style={{padding: '0 5px'}}>
					<Button id="btnLiveLink" type="primary" onClick={getLiveLink} size="large">
						<Trans i18nKey={'buttons.liveLink'} />
					</Button>
				</div>
			</Access>
			<div style={{padding: '0 5px'}}>
				<Access
					access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.createReport}
					roles={userRoles.default}
				>
					<ReportButton onClick={props.report} loadingReport={props.loadingReport} />
				</Access>
			</div>
			<Access
				access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.createExcelReport}
				roles={userRoles.default}
			>
				<div style={{display: 'flex'}}>
					<ExportButton
						ExportType={ExportType.Excel}
						WithGeoData={props.exportShowGeo}
						ExportFunction={props.exportFunction}
						Disabled={false}
					></ExportButton>
					<ExportButton
						ExportType={ExportType.Csv}
						WithGeoData={props.exportShowGeo}
						ExportFunction={props.exportFunction}
						Disabled={false}
					></ExportButton>
				</div>
			</Access>
		</div>
	);
};
